export const yellow = {
  yellow50: "#fff9e7",
  yellow100: "#ffebb3",
  yellow200: "#ffe18f",
  yellow300: "#ffd35c",
  yellow400: "#ffcb3c",
  yellow500: "#ffbe0b",
  yellow600: "#e8ad0a",
  yellow700: "#b58708",
  yellow800: "#8c6906",
  yellow900: "#6b5005",
};

export const red = {
  red50: "#fbecee",
  red100: "#f4c3c9",
  red200: "#eea6af",
  red300: "#e67e8b",
  red400: "#e16575",
  red500: "#da3e52",
  red600: "#c6384b",
  red700: "#9b2c3a",
  red800: "#78222d",
  red900: "#5c1a22",
};

export const blue = {
  blue50: "#eceffd",
  blue100: "#e3e7fc",
  blue200: "#c5cefa",
  blue300: "#4361ee",
  blue400: "#3c57d6",
  blue500: "#364ebe",
  blue600: "#3249b3",
  blue700: "#283a8f",
  blue800: "#1e2c6b",
  blue900: "#172253",
};

export const green = {
  green50: "#ecf7f0",
  green100: "#c5e6d1",
  green200: "#a9daba",
  green300: "#82c99b",
  green400: "#69bf87",
  green500: "#44af69",
  green600: "#3e9f60",
  green700: "#307c4b",
  green800: "#25603a",
  green900: "#1d4a2c",
};

export const grey = {
  grey50: "#f8f8f8",
  grey100: "#eaeaea",
  grey200: "#e0e0e0",
  grey300: "#d1d1d1",
  grey400: "#c9c9c9",
  grey500: "#bbbbbb",
  grey600: "#aaaaaa",
  grey700: "#858585",
  grey800: "#1C1C1C",
  grey900: "#4f4f4f",
};

export const darkGrey = {
  grey50: "#f8f8f8",
  grey100: "#eaeaea",
  grey200: "#e0e0e0",
  grey300: "#767676",
  grey400: "#5C5C5C",
  grey500: "#333333",
  grey600: "#aaaaaa",
  grey700: "#858585",
  grey800: "#1C1C1C",
  grey900: "#151515",
};

export const violet = {
  violet50: "#f3ebfd",
  violet100: "#d9c1f9",
  violet200: "#c6a3f6",
  violet300: "#ac7af2",
  violet400: "#9c60f0",
  violet500: "#8338ec",
  violet600: "#7733d7",
  violet700: "#5d28a8",
  violet800: "#481f82",
  violet900: "#371863",
};
