import { createContext, useReducer, ReactElement, useCallback, useContext } from "react";

export type EventsData = {
  date: string;
  name: string;
  GeoJson: { coordinates: number[]; type: string };
  id: string;
  type: string;
  description: string;
  interest: number;
  country_name: string;
};

type StateType = {
  eventsList: EventsData[];
  filtersQuery?: object | null;
  selectedEvent?: string | null;
  eventData: EventsData | {};
  summaryOfAnEvent: "" | {};
  targetRegion: string;
};

const now = new Date();
const initState: StateType = {
  eventsList: [],
  filtersQuery: {
    ordering: "-start_date",
    description__icontains: null,
    start_date__gte: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30).toISOString().split("T")[0],
    include_green_alert: "true",
  },
  selectedEvent: "",
  summaryOfAnEvent: "",
  eventData: {},
  targetRegion: "all",
};

const enum REDUCER_ACTION_TYPE {
  EVENTS_LIST,
  SELECT_EVENT,
  SELECTED_EVENT_DATA,
  SUMMARY_OF_AN_EVENT_DATA,
  FILTERS_QUERY_STRING,
  TARGET_REGION,
}

type EventsListPayload = EventsData[] | [];
type SelectedEventPayload = string;
type SelectedEventDataPayload = EventsData | {};
type SummaryOfAnEventPayload = "" | {};
type FiltersQueryString = object | null | undefined;
type ReducerAction = {
  type: REDUCER_ACTION_TYPE;
  payload:
    | EventsListPayload
    | SelectedEventPayload
    | SelectedEventDataPayload
    | SummaryOfAnEventPayload
    | FiltersQueryString;
};

const reducer = (state: StateType, action: ReducerAction): StateType => {
  switch (action.type) {
    case REDUCER_ACTION_TYPE.EVENTS_LIST:
      return { ...state, eventsList: (action.payload as EventsListPayload) ?? [] };
    case REDUCER_ACTION_TYPE.SELECT_EVENT:
      return { ...state, selectedEvent: (action.payload as SelectedEventPayload) ?? "" };
    case REDUCER_ACTION_TYPE.SELECTED_EVENT_DATA:
      return { ...state, eventData: (action.payload as SelectedEventDataPayload) ?? {} };
    case REDUCER_ACTION_TYPE.SUMMARY_OF_AN_EVENT_DATA:
      return { ...state, eventData: (action.payload as SummaryOfAnEventPayload) ?? "" };
    case REDUCER_ACTION_TYPE.FILTERS_QUERY_STRING:
      const data = action.payload;
      if (data) {
        return { ...state, filtersQuery: ({ ...state.filtersQuery, ...(data as object) } as FiltersQueryString) ?? {} };
      } else {
        return { ...state, filtersQuery: (initState.filtersQuery as FiltersQueryString) ?? {} };
      }
    case REDUCER_ACTION_TYPE.TARGET_REGION:
      return { ...state, targetRegion: (action.payload as string) ?? "" };
    default:
      throw new Error();
  }
};

const useEventsContext = (initState: StateType) => {
  const [state, dispatch] = useReducer(reducer, initState);
  const { filtersQuery } = useEvents();

  const handleSetEventsList = useCallback((data: EventsData[]) => {
    dispatch({
      type: REDUCER_ACTION_TYPE.EVENTS_LIST,
      payload: data,
    });
  }, []);

  const handleSetFiltersQuery = useCallback((query?: object | null) => {
    dispatch({
      type: REDUCER_ACTION_TYPE.FILTERS_QUERY_STRING,
      payload: query,
    });
  }, []);

  const handleSelectEvent = useCallback((eventId: string) => {
    dispatch({
      type: REDUCER_ACTION_TYPE.SELECT_EVENT,
      payload: eventId,
    });
  }, []);

  const handleSelectedEventData = useCallback((data: SelectedEventDataPayload) => {
    dispatch({
      type: REDUCER_ACTION_TYPE.SELECTED_EVENT_DATA,
      payload: data,
    });
  }, []);

  const handleSummaryOfAnEventData = useCallback((eventId: SummaryOfAnEventPayload) => {
    dispatch({
      type: REDUCER_ACTION_TYPE.SUMMARY_OF_AN_EVENT_DATA,
      payload: eventId,
    });
  }, []);

  const handleSelectRegion = useCallback((region: string) => {
    dispatch({
      type: REDUCER_ACTION_TYPE.TARGET_REGION,
      payload: region,
    });
  }, []);

  return {
    state,
    handleSetEventsList,
    handleSetFiltersQuery,
    handleSelectEvent,
    handleSummaryOfAnEventData,
    handleSelectedEventData,
    handleSelectRegion,
  };
};

type useEventsContextType = ReturnType<typeof useEventsContext>;

const initContextState: useEventsContextType = {
  state: initState,
  handleSetEventsList: (data: EventsData[] | []) => {},
  handleSetFiltersQuery: (query?: object | null) => {},
  handleSelectEvent: (eventId: string) => {},
  handleSummaryOfAnEventData: (eventId: SummaryOfAnEventPayload) => {},
  handleSelectedEventData: (data: SelectedEventDataPayload) => {},
  handleSelectRegion: (region: string) => {},
};

export const EventsContext = createContext<useEventsContextType>(initContextState);

type ChildrenType = {
  children?: ReactElement | ReactElement[] | undefined;
};

export const EventsProvider = ({ children }: ChildrenType): ReactElement => {
  return <EventsContext.Provider value={useEventsContext(initState)}>{children}</EventsContext.Provider>;
};

type FiltersQuery = {
  category?: string;
  ordering?: string;
  start_date__gte?: string;
  start_date__lte?: string;
  description__icontains?: string | null;
  include_green_alert?: string | null;
};

type useEventsHookType = {
  eventsList: EventsData[] | [];
  filtersQuery?: FiltersQuery | null;
  selectedEvent?: string | null | "";
  summaryOfAnEvent?: string | null | "" | {};
  handleSetEventsList: (data: EventsData[] | []) => void;
  handleSetFiltersQuery: (query?: object | null) => void;
  handleSelectEvent: (eventId: string) => void;
  handleSummaryOfAnEventData: (eventId: string) => void;
  handleSelectedEventData: (data: SelectedEventDataPayload) => void;
  handleSelectRegion: (region: string) => void;
  targetRegion: string;
};

export const useEvents = (): useEventsHookType => {
  const {
    state: { eventsList, filtersQuery, selectedEvent, summaryOfAnEvent, targetRegion },
    handleSetEventsList,
    handleSetFiltersQuery,
    handleSelectEvent,
    handleSummaryOfAnEventData,
    handleSelectedEventData,
    handleSelectRegion,
  } = useContext(EventsContext);
  return {
    eventsList,
    filtersQuery,
    selectedEvent,
    summaryOfAnEvent,
    handleSetFiltersQuery,
    handleSetEventsList,
    handleSelectEvent,
    handleSummaryOfAnEventData,
    handleSelectedEventData,
    handleSelectRegion,
    targetRegion,
  };
};
