import { AxiosInstance, AxiosRequestConfig } from "axios";
import { isValidToken } from "./jwt";

export type AccessTokenType = {
  accessToken: string;
};

interface AuthRequestConfig {
  /**
   * @default true
   */
  shouldAuthenticate?: boolean;
}

declare module "axios" {
  interface AxiosRequestConfig extends AuthRequestConfig {}
}

export const createAuthInterceptor = (
  instance: AxiosInstance,
  onAuthenticate: (config: AxiosRequestConfig) => Promise<AxiosRequestConfig>,
) => {
  return instance.interceptors.request.use((config: any) => {
    const { shouldAuthenticate = true } = config;
    if (shouldAuthenticate) {
      return onAuthenticate(config);
    }
    return config;
  });
};

export const applyAuthInterceptor = (instance: AxiosInstance) =>
  createAuthInterceptor(instance, async (config: any) => {    
    const token = localStorage.getItem('accessToken') 
    if (token && isValidToken(token)) {
      config.headers.accesstoken = token;
    }

    return config;
  });
