
import { AxiosError, AxiosResponse } from "axios";
import API_URLS from "../constants/apiUrls";
import xhrService from "../services/xhr";
import { UseMutationResult, useMutation } from "react-query";



export type LoginPayload = {
    username: string;
    password: string;
};
export type LoginMutationResponse = {
    user_id: string;
    AccessToken: Token;
}; 

export type AccessTokenType = {
    accessToken: string;
}

type Token = string;

type TData = AxiosResponse<LoginMutationResponse>;
type TError = AxiosError<{errors: string[]}>;
type TVariables = LoginPayload;
type TContext = any;
// type TContext = Api.MutationContext;

async function fn(variables: TVariables) {
    return xhrService.post<
        any,
        AxiosResponse<LoginMutationResponse>
    >(API_URLS.auth.signin, variables, { shouldAuthenticate: false })
}

export const useSignin = (): UseMutationResult<
    TData,
    TError,
    TVariables,
    TContext
> => {
    return useMutation(fn);
};


