import * as React from "react";
import { Header } from "../header";
import { Outlet } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import loadable from "@loadable/component";
import { NotifDrawerProvider } from "../../context/NotificationSettings/NotificationDrawerContext";
const PreferencesSettings = loadable(() => import("../../components/notificationSettings/preferencesSettings"));

export const MainLayout = () => {
  const styles = {
    container: {
      padding: "24px !important",
    },
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container sx={styles.container}>
        <NotifDrawerProvider>
          <Header />
          <Outlet />
          <PreferencesSettings />
        </NotifDrawerProvider>
      </Grid>
    </React.Fragment>
  );
};

export default MainLayout;
