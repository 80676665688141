import { ReactNode, createContext, useEffect, useReducer } from "react";
import { isValidToken } from "../services/xhr/interceptors/jwt";
import { useSignin } from "../apis/useSignin";
import { useNavigate } from "react-router-dom";
import { useProfile } from "../apis/useProfile";

export type AuthUser = null | string;

export type AuthContextType = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user_id: AuthUser;
  method: "jwt";
  signin: (username: string, password: string) => Promise<void>;
  signout: () => void;
};

enum Types {
  Initial = "INITIALIZE",
  SignIn = "SIGNIN",
  Signout = "SIGNOUT",
}

type AuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.SignIn]: {
    user: AuthUser;
  };
  [Types.Signout]: undefined;
};

export type AuthState = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user_id: AuthUser;
};

const initialState: AuthState = {
  isAuthenticated: true,
  isInitialized: false,
  user_id: null,
};

const reducer = (state: AuthState, action: any) => {
  switch (action.type) {
    case "INITIALIZE":
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user_id: action.payload.user_id,
      };
    case "SIGNIN":
      return {
        ...state,
        isAuthenticated: true,
        user_id: action.payload.user_id,
      };
    case "SIGNOUT":
      return {
        ...state,
        isAuthenticated: false,
        user_id: "",
      };
    default:
      return state;
  }
};

const AuthContext = createContext<AuthContextType | null>(null);

const initialize = async (dispatch: (value: any) => void, profileQuery: () => Promise<any>): Promise<void> => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken && isValidToken(accessToken)) {
      await profileQuery();
      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: true,
          user_id: null,
        },
      });
      return;
    }

    dispatch({
      type: Types.Initial,
      payload: {
        isAuthenticated: false,
        user_id: null,
      },
    });
  } catch (err) {
    dispatch({
      type: Types.Initial,
      payload: {
        isAuthenticated: false,
        user_id: null,
      },
    });
  }
};

let didInit = false;

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  let navigate = useNavigate();

  /* API CALL HOOKS */
  const { mutateAsync: profileQuery } = useProfile();
  const { mutateAsync: signinMutation } = useSignin();

  useEffect(() => {
    if (!didInit) {
      didInit = true;
      // ✅ Only runs once per app load
      initialize(dispatch, profileQuery);
    }
  }, []);

  const signin = async (username: string, password: string): Promise<void> => {
    const { data } = await signinMutation({ username, password });
    const { AccessToken, user_id } = data;
    localStorage.setItem("accessToken", AccessToken);
    localStorage.setItem("user_id", user_id);

    dispatch({
      type: Types.SignIn,
      payload: {
        user_id,
      },
    });
    navigate("/");
  };

  const signout = (): void => {
    dispatch({ type: Types.Signout });
    navigate("/signin");
    localStorage.setItem("accessToken", "");
    localStorage.setItem("user_id", "");
  };
  const updateUser = () => {
    initialize(dispatch, profileQuery);
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signin,
        signout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
export { AuthContext, AuthProvider };
