import axios from "axios";
import { compose } from "lodash/fp";

import { applyAuthInterceptor } from "./interceptors/auth";

const xhrService = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

compose(applyAuthInterceptor)(xhrService);

export const generateCancelToken = () => axios.CancelToken.source();
export * from "axios";
export default xhrService;
