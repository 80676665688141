import React, { createContext, useContext, useState, FC } from "react";

interface NotifDrawerContextType {
  drawerOpen: boolean;
  toggleNotifSettingDrawer: () => void;
}

const NotifDrawerContext = createContext<NotifDrawerContextType | undefined>(undefined);

export function useNotifDrawer() {
  const context = useContext(NotifDrawerContext);
  if (!context) {
    throw new Error("useNotifDrawer must be used within a NotifDrawerProvider");
  }
  return context;
}

interface INotifDrawerProvider {
  children?: React.ReactElement | React.ReactElement[];
}

export const NotifDrawerProvider: FC<INotifDrawerProvider> = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleNotifSettingDrawer = () => {
    setDrawerOpen(drawerOpen ? false : true);
  };

  return (
    <NotifDrawerContext.Provider value={{ drawerOpen, toggleNotifSettingDrawer }}>
      {children}
    </NotifDrawerContext.Provider>
  );
};
