

import { AxiosError } from 'axios';
import {
    useMutation,
    MutationOptions,
    useQuery,
    UseQueryResult,
} from 'react-query';
import xhrService from '../services/xhr';
import API_URLS from '../constants/apiUrls';





type TData = any;
type QueryFnData = {
    token: string;
    user: any;
};

type TError = AxiosError<{errors: string[]}>
const user_id = localStorage.getItem("user_id");


async function fn() {
    const { data } = await xhrService.get<QueryFnData>(API_URLS.user.profile(user_id));
    return data;
}

type Props = {
    options?: MutationOptions<TData, TError>;
};

export const useProfile = ({ options }: Props = {}) => {

    return useMutation(fn, options);
};

export const useProfileQuery = (): UseQueryResult<TData, TError> => {
    return useQuery(API_URLS.user.profile(user_id), fn);
};
