import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { MainLayout } from "./layout/mainLayout";
import { Main } from "./pages/main";
import { createTheme, ThemeProvider } from "@mui/material";
import { ReactQueryService } from "./services/reactQuery/reactQueryService";
import { EventsProvider } from "./context/EventsContext";
import { grey } from "./colors";
import { AuthProvider } from "./context/AuthContext";
import MainRoutes from "./components/routes/routes";

const theme = createTheme({
  typography: {
    fontFamily: ["Lato"].join(","),
    body1: {
      fontWeight: "300",
      fontSize: "12px",
    },
    body2: { fontWeight: "400", fontSize: "14px", lineHeight: "16.8px" },
    subtitle1: { fontWeight: "600", fontSize: "14px" },
    subtitle2: { fontWeight: "400", fontSize: "12px" },
    h5: { fontWeight: "700", fontSize: "16px" },
    h6: { fontWeight: "500", fontSize: "16px" },

    caption: { fontWeight: "400", fontSize: "14px" },
  },

  palette: {
    secondary: {
      main: "#ffcb3c",
      light: "#ffd35c",
      dark: "#e8ad0a",
    },
    primary: {
      main: "#4361ee",
      light: "#c5cefa",
      dark: "#3249b3",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: 700,
          borderRadius: "8px",
          fontSize: "14px",
          boxShadow: "none",
        },
        text: {
          marginTop: 0,
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          border: "1px solid #EAEAEA",
          borderRadius: "8px",
          fontSize: "13px",
          color: "#4F4F4F",
          fontWeight: 600,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontWeight: 400,
          borderBottom: `1px solid ${grey.grey100}`,
          "&:last-child": {
            borderBottom: "none",
          },
        },
      },
    },
  },
});

function App() {
  return (
    <ReactQueryService>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <EventsProvider>
            <AuthProvider>
              <MainRoutes />
            </AuthProvider>
          </EventsProvider>
        </BrowserRouter>
      </ThemeProvider>
    </ReactQueryService>
  );
}

export default App;
